import React from "react";

import Layout from "../components/Layout";

const AboutPage = () => (
    <Layout>
        <div id="main">
            <div className="about_section_banner">
                <div className="about_banner">
                    <h1>Acerca de LevenMas</h1>
                </div>
            </div>
            <div className="inner">
                <div className="about_section_1">
                    <h1 className="about_section_1__title">Conoce más de nosotros</h1>
                    <p className="about_section_1__paragraph">En nuestro Despacho Contable sabemos que la contabilidad y
                        la prevención fiscal es un tema
                        delicado y no se puede dejar en manos de cualquiera.<br/><br/>

                        Una mala práctica, un descuido o simplemente una mala organización de tu contabilidad puede
                        traer multas totalmente innecesarias y embargos del SAT.<br/><br/>

                        Con nosotros no tendrás que preocuparte de nada. Somos un equipo de contadores, abogados y
                        fiscalistas preparados y listos para prevenir situaciones incómodas y solucionarlas en caso de
                        que se presenten.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
);

export default AboutPage;
